
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    function onClick1(e) {
        window.Convermax.onFilterClick(e);
    }
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [this.CustomFacetDialogButton(function () {
            return _createElement('div', { 'className': 'cm_results-filter-button cm_desktop-hide cm_CustomFacetDialogButton' }, [_createElement('svg', {
                    'width': '26',
                    'height': '22',
                    'viewBox': '0 0 26 22',
                    'fill': 'none',
                    'xmlns': 'http://www.w3.org/2000/svg',
                    'aria-hidden': 'true',
                    'key': '860'
                }, _createElement('path', {
                    'd': 'M11.375 1.57143V1.17857C11.375 0.530357 10.8266 0 10.1562 0H6.09375C5.42344 0 4.875 0.530357 4.875 1.17857V1.57143H0V4.71429H4.875V5.10714C4.875 5.75536 5.42344 6.28571 6.09375 6.28571H10.1562C10.8266 6.28571 11.375 5.75536 11.375 5.10714V4.71429H26V1.57143H11.375ZM6.5 4.71429V1.57143H9.75V4.71429H6.5ZM21.125 9.03571C21.125 8.3875 20.5766 7.85714 19.9062 7.85714H15.8438C15.1734 7.85714 14.625 8.3875 14.625 9.03571V9.42857H0V12.5714H14.625V12.9643C14.625 13.6125 15.1734 14.1429 15.8438 14.1429H19.9062C20.5766 14.1429 21.125 13.6125 21.125 12.9643V12.5714H26V9.42857H21.125V9.03571ZM16.25 12.5714V9.42857H19.5V12.5714H16.25ZM11.375 16.8929C11.375 16.2446 10.8266 15.7143 10.1562 15.7143H6.09375C5.42344 15.7143 4.875 16.2446 4.875 16.8929V17.2857H0V20.4286H4.875V20.8214C4.875 21.4696 5.42344 22 6.09375 22H10.1562C10.8266 22 11.375 21.4696 11.375 20.8214V20.4286H26V17.2857H11.375V16.8929ZM6.5 20.4286V17.2857H9.75V20.4286H6.5Z',
                    'fill': '#D9D9D9'
                }))], _createElement('span', {}, 'filter by'));
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader__main cm_SearchHeader' }, _createElement('div', {
                'role': 'status',
                'className': 'cm_total-hits'
            }, _createElement('span', {}, this.totalHits > 12 ? [
                '\n    Showing ',
                this.pageFrom,
                '-',
                this.pageTo,
                ' of ',
                this.totalHits,
                '\n  '
            ] : null, this.totalHits <= 12 ? [
                'Showing ',
                this.totalHits
            ] : null)));
        }, {
            widgetName: 'search-header-main',
            items: undefined
        })], _createElement('div', { 'className': 'cm_results-main' }, _createElement('div', { 'className': 'cm_FacetPanel-container' }, _createElement('div', {
        'className': 'cm_FacetPanel',
        'onClick': onClick1.bind(this)
    }, _createElement('div', {
        'id': 'cm_FacetPanel_status',
        'className': 'visually-hidden',
        'role': 'status'
    }), [this.FilterChips(function () {
            return _createElement('div', { 'className': 'cm_FilterChips' }, [this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'key': `${ this.field }|${ i }`,
                                        'className': 'cm_star cm_star__' + (!(this.value - i) || this.value - i < 0.25 ? 'empty' : this.value - i < 0.75 ? 'half' : 'full')
                                    });
                                }
                                function scopeInchMm1_rangeChip() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('button', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'aria-label': `Choose to discard the ${ this.value }`
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '412' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '538' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '638' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '7730'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('button', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'aria-label': `Remove ${ this.field }: ${ this.value }`
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '3140'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement.apply(this, [
                                    'button',
                                    {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'aria-label': `Choose to discard the ${ this.value }`
                                    },
                                    _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '),
                                    _map([
                                        0,
                                        1,
                                        2,
                                        3,
                                        4
                                    ], repeatI1_reviewChip.bind(this)),
                                    this.value !== '5' ? _createElement('span', { 'key': '438' }, ' and up') : null
                                ]), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '5420'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'cm_btn button button--inverted cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Start over\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.Facets(function () {
            return _createElement('div', { 'className': 'cm_Facets' }, [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('button', {
                                                'className': 'facetdiv ' + this.facetDivClass,
                                                'role': this.IsMultiselect ? 'checkbox' : 'radio',
                                                'aria-checked': this.isSelected,
                                                'aria-label': this.value,
                                                'tabIndex': '0'
                                            }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'tabIndex': '-1',
                                                'checked': this.isSelected
                                            }), _createElement('a', { 'className': 'facetentrykey' }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('button', {
                                                'className': 'facetdiv ' + this.facetDivClass,
                                                'role': this.IsMultiselect ? 'checkbox' : 'radio',
                                                'aria-checked': this.isSelected,
                                                'tabIndex': '0'
                                            }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'tabIndex': '-1',
                                                'checked': this.isSelected
                                            }), _createElement('a', { 'className': 'facetentrykey' }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1541'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1543'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '3901'
                                                }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                function scopeInchMm4() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', {
                                        'className': 'cmTemplate_sliderFacet',
                                        'key': '13162'
                                    }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Min',
                                                            'className': 'cm_inputMin'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                                'className': 'separator',
                                                'key': '334'
                                            }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                        return _createElement('div', {
                                                            'placeholder': 'Max',
                                                            'className': 'cm_inputMax'
                                                        });
                                                    }, {
                                                        widgetName: 'undefined',
                                                        items: undefined
                                                    })]] : null, _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn button button--inverted',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]);
                                }
                                function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues6(Values, ValuesIndex) {
                                    return [Values(function () {
                                            function scopeStarFill1(i, iIndex) {
                                                var starFill = this.value - i;
                                                return _createElement('span', {
                                                    'key': i,
                                                    'className': 'cm_star cm_star__' + (starFill < 0.25 ? 'empty' : starFill < 0.75 ? 'half' : 'full')
                                                });
                                            }
                                            function repeatI2(i, iIndex, starFill) {
                                                return scopeStarFill1.apply(this, [
                                                    i,
                                                    iIndex
                                                ]);
                                            }
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, _createElement.apply(this, [
                                                'span',
                                                { 'className': 'cm_review-stars' },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI2.bind(this))
                                            ]), this.value !== '5' ? _createElement('span', {
                                                'className': 'cm_and-up',
                                                'key': '499'
                                            }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? [
                                    _createElement('div', {
                                        'className': 'facettitle',
                                        'data-cm-role': 'toggle-facet',
                                        'tabIndex': '0',
                                        'key': '561'
                                    }, _createElement('span', {}, this.name), this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle left',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '2380'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-angle down',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '39 30 565 565',
                                            'aria-hidden': 'true',
                                            'key': '5590'
                                        }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null),
                                    _createElement('div', {
                                        'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                        'key': '563'
                                    }, this.template === 'simpleFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_simpleFacet',
                                        'key': '1073'
                                    }, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                            'className': 'facetdiv',
                                            'key': '11591'
                                        }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (!this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                        'div',
                                        {
                                            'role': this.IsMultiselect ? 'checkbox' : 'radiogroup',
                                            'className': 'cmRepeater_ShowAlwaysValues'
                                        },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        {
                                            'role': this.IsMultiselect ? 'checkbox' : 'radiogroup',
                                            'className': 'cmRepeater_Values'
                                        },
                                        _map(this.Values, repeatValues2.bind(this))
                                    ]), this.needShowMore ? _createElement('span', {
                                        'className': 'facetdiv cm_show-all-container',
                                        'key': '3406'
                                    }, _createElement('button', {
                                        'className': 'cm_show-all',
                                        'data-cm-role': 'toggle-show-more',
                                        'tabIndex': '0',
                                        'role': 'button',
                                        'aria-expanded': !this.moreValuesToShow,
                                        'aria-label': this.moreValuesToShow ? `Show more ${ this.name }` : `Show less ${ this.name }`
                                    }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_selectFacet',
                                        'key': '3902'
                                    }, [this.select(function () {
                                            function repeatEntry1(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry3(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry4(entry, entryIndex) {
                                                return _createElement('div', {
                                                    'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, _createElement('div', {
                                                    'className': 'cm_dropdown_value',
                                                    'role': 'option',
                                                    'aria-selected': entry.selected
                                                }, entry.value));
                                            }
                                            return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'aria-label': `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'tabIndex': this.disabled ? '-1' : '0',
                                                'role': 'combobox',
                                                'aria-haspopup': 'listbox',
                                                'aria-autocomplete': 'none',
                                                'aria-controls': `${ this.title }-combobox`,
                                                'aria-selected': this.selectedEntries.length > 0
                                            }, this.useNativeDropdown ? [
                                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                    'value': '',
                                                    'key': 'null-option'
                                                }, '\n          ', '', this.title, '\n        ') : null,
                                                this.loading ? _createElement('option', {
                                                    'key': 'loading-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '12331'
                                                    }, 'Popular Makes'),
                                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '12334'
                                                    }, 'All Makes'),
                                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                                ] : null,
                                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                                'key': '_current',
                                                'value': '_current'
                                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null), [_createElement('svg', {
                                                    'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                                                    'width': '11',
                                                    'height': '7',
                                                    'viewBox': '0 0 11 7',
                                                    'fill': 'none',
                                                    'xmlns': 'http://www.w3.org/2000/svg',
                                                    'aria-hidden': 'true',
                                                    'key': '30290'
                                                }, _createElement('path', {
                                                    'fillRule': 'evenodd',
                                                    'clipRule': 'evenodd',
                                                    'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                                                    'fill': '#1A1717'
                                                }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'role': 'listbox',
                                                    'key': '3521'
                                                },
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_priceFacet',
                                        'key': '10226'
                                    }, !this.ranges.length ? [
                                        _createElement('div', { 'key': '103101' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                        '\n  ',
                                        this.slider,
                                        '\n  ',
                                        _createElement('div', {
                                            'className': 'cm_flex',
                                            'key': '103103'
                                        }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                    ] : null, this.ranges.length ? [_createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cmRepeater_Values',
                                                'key': '105741'
                                            },
                                            _map(this.Values, repeatValues3.bind(this))
                                        ])] : null, [this.Inputs(function () {
                                            return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min price',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max price',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })], _createElement('button', {
                                                'type': 'button',
                                                'className': 'cm_btn button button--inverted',
                                                'onClick': this.setCustomRange
                                            }, '\n    GO\n  '));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })]) : null, this.template === 'sliderFacet' ? scopeInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                        'className': 'cmTemplate_reviewFacet',
                                        'key': '14924'
                                    }, _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_ShowAlwaysValues' },
                                        _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                                    ]), _createElement.apply(this, [
                                        'div',
                                        { 'className': 'cmRepeater_Values' },
                                        _map(this.Values, repeatValues6.bind(this))
                                    ])) : null)
                                ] : null, this.template === 'toggleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_toggleFacet',
                                    'key': '18072'
                                }, _createElement('div', {
                                    'className': 'facettitle cm_facet-toggle',
                                    'role': 'group',
                                    'tabIndex': '0'
                                }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                    'className': 'cm_facet-toggle_input',
                                    'type': 'checkbox',
                                    'role': 'checkbox',
                                    'aria-label': this.values[0].value,
                                    'checked': this.isToggled,
                                    'onClick': this.toggleFacet,
                                    'tabIndex': '0'
                                }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchVehicleWidget(function () {
            function repeatSelects1(selects, selectsIndex) {
                return [selects(function () {
                        function repeatEntry1(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry2(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry3(entry, entryIndex) {
                            return _createElement('option', {
                                'disabled': this.loading,
                                'value': entry.term,
                                'key': entry.value
                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                        }
                        function repeatEntry4(entry, entryIndex) {
                            return _createElement('div', {
                                'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                'key': entry.value,
                                'onClick': () => this.onChange(entry.term)
                            }, _createElement('div', {
                                'className': 'cm_dropdown_value',
                                'role': 'option',
                                'aria-selected': entry.selected
                            }, entry.value));
                        }
                        return _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                            'aria-label': `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`
                        }, _createElement('select', {
                            'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                            'onChange': this.onChange,
                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                            'disabled': this.disabled,
                            'tabIndex': this.disabled ? '-1' : '0',
                            'role': 'combobox',
                            'aria-haspopup': 'listbox',
                            'aria-autocomplete': 'none',
                            'aria-controls': `${ this.title }-combobox`,
                            'aria-selected': this.selectedEntries.length > 0
                        }, this.useNativeDropdown ? [
                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                'value': '',
                                'key': 'null-option'
                            }, '\n          ', '', this.title, '\n        ') : null,
                            this.loading ? _createElement('option', {
                                'key': 'loading-option',
                                'disabled': true
                            }, '...loading...') : null,
                            this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '12101'
                                }, 'Popular Makes'),
                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                _createElement('option', {
                                    'className': 'cm_option_title',
                                    'disabled': true,
                                    'key': '12104'
                                }, 'All Makes'),
                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                            ] : null,
                            this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                            'key': '_current',
                            'value': '_current'
                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null), [_createElement('svg', {
                                'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                                'width': '11',
                                'height': '7',
                                'viewBox': '0 0 11 7',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'aria-hidden': 'true',
                                'key': '30060'
                            }, _createElement('path', {
                                'fillRule': 'evenodd',
                                'clipRule': 'evenodd',
                                'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                                'fill': '#1A1717'
                            }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                            'div',
                            {
                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                'role': 'listbox',
                                'key': '3498'
                            },
                            this.loading ? _createElement('div', {
                                'className': 'option',
                                'key': 'null-option',
                                'disabled': true
                            }, '...loading...') : null,
                            !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                        ]) : null);
                    }, { count: undefined })];
            }
            function onKeyDown2(e) {
                {
                    if (e.type === 'keydown' && e.key === 'Enter') {
                        this.selectVehicle();
                    }
                }
            }
            function onKeyDown3(e) {
                {
                    if (e.type === 'keydown' && e.key === 'Enter') {
                        this.discardVehicle();
                    }
                }
            }
            return _createElement('div', {
                'className': 'cm_vehicle-widget cm_vehicle-widget__search cm_SearchVehicleWidget',
                'role': 'form'
            }, this.template === 'active' ? _createElement('div', {
                'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                'key': '92'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.selectYourVehicleTitle || 'Select Your Vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                'div',
                { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                _map(this.selects, repeatSelects1.bind(this))
            ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
                'onClick': this.selectVehicle,
                'onKeyDown': onKeyDown2.bind(this),
                'disabled': !this.allowToSetVehicle,
                'role': 'button',
                'tabIndex': this.allowToSetVehicle ? '0' : '-1'
            }, '\n    Add vehicle\n  '), _createElement('div', {
                'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--inverted',
                'onClick': this.discardVehicle,
                'onKeyDown': onKeyDown3.bind(this),
                'disabled': !this.allowToDiscardVehicle,
                'role': 'button',
                'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
            }, '\n    Clear vehicle\n  ')))) : null, this.template === 'locked' ? _createElement('div', {
                'className': 'cm_vehicle-widget_label cmTemplate_locked',
                'key': '7430'
            }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, '\n  ', this.vehicleString, '\n  ', _createElement('span', { 'className': 'cm_vehicle-widget_subtitle' }, this.fitmentSearchTitle)), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                'className': 'cm_vehicle-widget_button button',
                'onClick': this.changeVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Change Vehicle\n    '), _createElement('div', {
                'className': 'cm_vehicle-widget_button button button--inverted',
                'onClick': this.discardVehicle,
                'role': 'button',
                'tabIndex': '0'
            }, '\n      Clear Selection\n    ')))) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })])), _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                '\n    This ',
                this.pageType,
                ' doesn\'t have any\n    ',
                !!this.vehicleString ? [
                    ' parts that fit ',
                    _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                ] : null,
                !this.vehicleString ? [' products.'] : null
            ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                '\n    Press ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '26721'
                }, 'here'),
                ' to clear\n    ',
                !this.vehicleString ? [' selection. '] : null,
                this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3065'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3280'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3509'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '36550' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '37420' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '38250' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '36204' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        function mergeProps(inline, external) {
                            var res = Object.assign({}, inline, external);
                            if (inline.hasOwnProperty('style')) {
                                res.style = _.defaults(res.style, inline.style);
                            }
                            if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                res.className = external.className + ' ' + inline.className;
                            }
                            return res;
                        }
                        function scopeOptionArrTitleUrl2(option, optionIndex) {
                            var optionArr = option.split('|');
                            var title = optionArr[0];
                            var url = optionArr[1];
                            return [_createElement('a', {
                                    'className': 'swatches__form--label h7 pt11 pb8 px16 mb0',
                                    'aria-label': title,
                                    'href': url,
                                    'key': '12751'
                                }, '\n                  ', title, '\n                ')];
                        }
                        function repeatOption3(option, optionIndex, optionArr, title, url) {
                            return scopeOptionArrTitleUrl2.apply(this, [
                                option,
                                optionIndex
                            ]);
                        }
                        return _createElement('li', {
                            'className': 'splide__slide',
                            'data-product-sku': this.removeHTML(this.sku)
                        }, _createElement('div', { 'className': 'product-card a-center' }, _createElement('div', { 'className': 'product-card-top' }, _createElement('h2', mergeProps({ 'className': 'product-card__sku h7 a-left mb23 p0' }, { dangerouslySetInnerHTML: { __html: this.sku } })), _createElement('div', { 'className': 'product-card__imageContainer mb4' }, _createElement('a', {
                            'href': this.url,
                            'title': this.removeHTML(this.title),
                            'tabIndex': '-1'
                        }, _createElement('div', { 'className': 'product-card__img-wrapper image-ratio--square' }, _createElement('div', { 'className': 'box-ratio' }, _createElement('img', {
                            'src': this.imageOrDefault(this.resizeImage(this.image)),
                            'alt': this.removeHTML(this.title),
                            'onError': this.onImageError,
                            'loading': 'lazy'
                        }))))), _createElement('div', { 'className': 'product-card__textContainer' }, _createElement('a', mergeProps({
                            'className': 'product-card__itemTitle h5 mt24 mb0',
                            'href': this.url
                        }, { dangerouslySetInnerHTML: { __html: this.title } })), !this.discontinued ? _createElement('span', {
                            'className': 'b-border mb24 mt18',
                            'key': '916'
                        }) : null), this.option_snippets ? _createElement('div', {
                            'className': 'product-card__swatches',
                            'key': '998'
                        }, _createElement('div', { 'className': 'h7 sb-grey mb0' }, this.option_name), _createElement('div', { 'className': 'mt8' }, _createElement('div', { 'className': 'swatches swatches__container mb16' }, _createElement.apply(this, [
                            'div',
                            { 'className': 'swatches__options-wrapper' },
                            _map(this.option_snippets, repeatOption3.bind(this))
                        ])))) : null, this.many_options ? _createElement('a', {
                            'href': this.url,
                            'className': 'product-card__options-link mt8 mb16',
                            'key': '1679'
                        }, '\n        See More Options\n      ') : null)), _createElement('div', { 'className': 'product-card-bottom mt4' }, _createElement('div', { 'className': 'product-card__itemPrice mb6 sb-red' }, _createElement('div', { 'className': 'price' }, _createElement('div', { 'className': 'price__container' }, _createElement('div', { 'className': 'price__regular' }, _createElement('div', { 'className': 'price__currency-wrap' }, _createElement('div', {}, _createElement('span', { 'className': 'price-item--regular typo__body-2 sb-red' }, '\n                  ', this.price_varies ? `From ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n                '), _createElement('span', { 'className': 'h7 m0 p0 sb-red' }, 'USD')))))))), _createElement('div', { 'className': 'cut-corner' }));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'ul',
                { 'className': 'splide__list cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '7690'
                        }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1136'
                    }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1554'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '1816'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2108'
                    }, _createElement('div', {}, this.pageType !== 'search' && !this.isFacetsSelected ? [
                        '\n    This ',
                        this.pageType,
                        ' doesn\'t have any\n    ',
                        !!this.vehicleString ? [
                            ' parts that fit ',
                            _createElement('span', { 'key': '23371' }, '\'', this.vehicleString, '\'.')
                        ] : null,
                        !this.vehicleString ? [' products.'] : null
                    ] : null, this.pageType === 'search' ? [_createElement('div', { 'key': '25411' }, 'No results were found for your search.')] : null, this.query || !!this.vehicleString ? [
                        '\n    Press ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '26721'
                        }, 'here'),
                        ' to clear\n    ',
                        !this.vehicleString ? [' selection. '] : null,
                        this.vehicleString && !this.isFacetsSelected ? ['\n      vehicle selection and browse all parts.\n    '] : null
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3065'
                    }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3280'
                    }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3509'
                    }, 'We failed to process your request', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '36550' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '37420' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '38250' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '36204' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })]))), [this.pagination(function () {
            function repeatButtons1(onEnter, buttons, buttonsIndex) {
                return [buttons(function () {
                        return this.template === 'prev' ? _createElement('div', {
                            'href': this.pageLink,
                            'tabIndex': '0',
                            'aria-label': 'Previous page',
                            'onKeyDown': onEnter,
                            'role': 'link',
                            'className': 'cmTemplate_prev'
                        }, _createElement('svg', {
                            'width': '7',
                            'height': '11',
                            'viewBox': '0 0 7 11',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'aria-hidden': 'true'
                        }, _createElement('path', {
                            'd': 'M1 1L5.17412 4.7567C5.6155 5.15395 5.6155 5.84605 5.17412 6.24329L1 10',
                            'stroke': '#1A1717'
                        }))) : this.template === 'next' ? _createElement('div', {
                            'href': this.pageLink,
                            'tabIndex': '0',
                            'aria-label': 'Next page',
                            'onKeyDown': onEnter,
                            'role': 'link',
                            'className': 'cmTemplate_next'
                        }, _createElement('svg', {
                            'width': '7',
                            'height': '11',
                            'viewBox': '0 0 7 11',
                            'fill': 'none',
                            'xmlns': 'http://www.w3.org/2000/svg',
                            'aria-hidden': 'true'
                        }, _createElement('path', {
                            'd': 'M1 1L5.17412 4.7567C5.6155 5.15395 5.6155 5.84605 5.17412 6.24329L1 10',
                            'stroke': '#1A1717'
                        }))) : this.template === 'delimiter' ? _createElement('div', { 'className': 'cmTemplate_delimiter' }, '...') : this.template === 'page' ? _createElement('div', {
                            'href': this.pageLink,
                            'aria-label': `Page ${ this.page } of ${ this.totalPages }`,
                            'className': (this.isActive ? 'active' : '') + ' cmTemplate_page',
                            'tabIndex': '0',
                            'onKeyDown': onEnter,
                            'role': 'link'
                        }, '\n    ', this.page, '\n  ') : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            function scopeOnEnter2() {
                var onEnter = e => {
                    if (e.type === 'keydown' && e.key === 'Enter') {
                        e.target.click();
                    }
                };
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_buttons' },
                    _map(this.buttons, repeatButtons1.bind(this, onEnter))
                ]);
            }
            return _createElement('div', { 'className': 'cm_pagination' }, scopeOnEnter2.apply(this, []));
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:CustomFacetDialogButton","cm:SearchHeader","cm:filterChips","cm:FilterChips","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:facets","cm:Facets","cm:SearchVehicleWidget","cm:message","cm:message","cm:SearchResult","cm:pagination"]