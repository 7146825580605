
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function QueryLabelRT () {
    return _createElement('div', { 'className': 'cm_query-label' }, [this.filterChips(function () {
            function repeatChips1(chips, chipsIndex) {
                return [chips(function () {
                        return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, this.name === 'query' ? [
                            '\u201C',
                            this.value,
                            '\u201D'
                        ] : null) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }) : this.template === 'rangeChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_filterChips' }, _createElement.apply(this, [
                'div',
                { 'className': 'cmRepeater_chips' },
                _map(this.chips, repeatChips1.bind(this))
            ]));
        }, {
            widgetName: 'filter-chips',
            items: undefined
        })]);
}
        export const componentNames = ["cm:filterChips"]