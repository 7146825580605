//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-520:_8984,_8864,_2996,_9216,_5792,_2333,_1492,_9516;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-520')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-520', "_8984,_8864,_2996,_9216,_5792,_2333,_1492,_9516");
        }
      }catch (ex) {
        console.error(ex);
      }