
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function fitmentTableRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, showVehicleSelector, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '69321'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function scopeShowVehicleSelector3(field, fieldIndex) {
                    var showVehicleSelector = this.vehicleSelector && field === 'Year';
                    return [
                        showVehicleSelector ? [[this.vehicleSelector(function () {
                                    return _createElement('div', { 'className': 'cm_vehicleSelector' }, [this.yearsSelect(function () {
                                            function repeatEntry1(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry3(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry4(entry, entryIndex) {
                                                return _createElement('div', {
                                                    'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, _createElement('div', {
                                                    'className': 'cm_dropdown_value',
                                                    'role': 'option',
                                                    'aria-selected': entry.selected
                                                }, entry.value));
                                            }
                                            return _createElement('div', { 'className': 'cm_yearsSelect' }, _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'aria-label': this.useNativeDropdown ? '' : `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`,
                                                'tabIndex': this.useNativeDropdown ? '-1' : '0'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'aria-label': this.useNativeDropdown ? this.title : '',
                                                'tabIndex': this.useNativeDropdown ? '0' : '-1'
                                            }, this.useNativeDropdown ? [
                                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                    'value': '',
                                                    'key': 'null-option'
                                                }, '\n          ', '', this.title, '\n        ') : null,
                                                this.loading ? _createElement('option', {
                                                    'key': 'loading-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '12561'
                                                    }, 'Popular Makes'),
                                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '12564'
                                                    }, 'All Makes'),
                                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                                ] : null,
                                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                                'key': '_current',
                                                'value': '_current'
                                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null), [_createElement('svg', {
                                                    'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                                                    'width': '11',
                                                    'height': '7',
                                                    'viewBox': '0 0 11 7',
                                                    'fill': 'none',
                                                    'xmlns': 'http://www.w3.org/2000/svg',
                                                    'aria-hidden': 'true',
                                                    'key': '30520'
                                                }, _createElement('path', {
                                                    'fillRule': 'evenodd',
                                                    'clipRule': 'evenodd',
                                                    'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                                                    'fill': '#1A1717'
                                                }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'role': 'listbox',
                                                    'key': '3544'
                                                },
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', { 'onClick': this.selectVehicle }, 'View All Parts'));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null,
                        !showVehicleSelector ? _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex, showVehicleSelector)) : null
                    ];
                }
                function repeatField4(field, fieldIndex) {
                    return _createElement('div', {
                        'className': 'fitment-cell fitment-field-' + field.toLowerCase(),
                        'key': this[field]
                    }, this[field] && this[field] !== 'Universal' ? scopeShowVehicleSelector3.apply(this, [
                        field,
                        fieldIndex
                    ]) : null);
                }
                return _createElement.apply(this, [
                    'div',
                    { 'className': 'fitment-row' },
                    _map(this.fields, repeatField4.bind(this))
                ]);
            }, { count: undefined })];
    }
    function repeatItems2(items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatFieldValue2(field, fieldIndex, showVehicleSelector, fieldValue, fieldValueIndex) {
                    return [_createElement('div', mergeProps({
                            'className': 'fitment-cell__value',
                            'key': '70971'
                        }, { dangerouslySetInnerHTML: { __html: fieldValue } }))];
                }
                function scopeShowVehicleSelector3(field, fieldIndex) {
                    var showVehicleSelector = this.vehicleSelector && field === 'Year';
                    return _createElement.apply(this, [
                        'div',
                        { 'className': 'fitment-cell' },
                        showVehicleSelector ? [[this.vehicleSelector(function () {
                                    return _createElement('div', { 'className': 'cm_vehicleSelector' }, [this.yearsSelect(function () {
                                            function repeatEntry1(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry2(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry3(entry, entryIndex) {
                                                return _createElement('option', {
                                                    'disabled': this.loading,
                                                    'value': entry.term,
                                                    'key': entry.value
                                                }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                            }
                                            function repeatEntry4(entry, entryIndex) {
                                                return _createElement('div', {
                                                    'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                                    'key': entry.value,
                                                    'onClick': () => this.onChange(entry.term)
                                                }, _createElement('div', {
                                                    'className': 'cm_dropdown_value',
                                                    'role': 'option',
                                                    'aria-selected': entry.selected
                                                }, entry.value));
                                            }
                                            return _createElement('div', { 'className': 'cm_yearsSelect' }, _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                                                'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                                'aria-label': this.useNativeDropdown ? '' : `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`,
                                                'tabIndex': this.useNativeDropdown ? '-1' : '0'
                                            }, _createElement('select', {
                                                'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                                                'onChange': this.onChange,
                                                'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                                'disabled': this.disabled,
                                                'aria-label': this.useNativeDropdown ? this.title : '',
                                                'tabIndex': this.useNativeDropdown ? '0' : '-1'
                                            }, this.useNativeDropdown ? [
                                                !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                    'value': '',
                                                    'key': 'null-option'
                                                }, '\n          ', '', this.title, '\n        ') : null,
                                                this.loading ? _createElement('option', {
                                                    'key': 'loading-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '12561'
                                                    }, 'Popular Makes'),
                                                    _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                    _createElement('option', {
                                                        'className': 'cm_option_title',
                                                        'disabled': true,
                                                        'key': '12564'
                                                    }, 'All Makes'),
                                                    _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                                ] : null,
                                                this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                            ] : null, !this.useNativeDropdown ? _createElement('option', {
                                                'key': '_current',
                                                'value': '_current'
                                            }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null), [_createElement('svg', {
                                                    'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                                                    'width': '11',
                                                    'height': '7',
                                                    'viewBox': '0 0 11 7',
                                                    'fill': 'none',
                                                    'xmlns': 'http://www.w3.org/2000/svg',
                                                    'aria-hidden': 'true',
                                                    'key': '30520'
                                                }, _createElement('path', {
                                                    'fillRule': 'evenodd',
                                                    'clipRule': 'evenodd',
                                                    'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                                                    'fill': '#1A1717'
                                                }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                    'role': 'listbox',
                                                    'key': '3544'
                                                },
                                                this.loading ? _createElement('div', {
                                                    'className': 'option',
                                                    'key': 'null-option',
                                                    'disabled': true
                                                }, '...loading...') : null,
                                                !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                            ]) : null));
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', { 'onClick': this.selectVehicle }, 'View All Parts'));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null,
                        !showVehicleSelector ? _map(`${ window.themeSettings?.locales?.ymm[this[field]?.toLowerCase()] || this[field] }`.split('\n'), repeatFieldValue2.bind(this, field, fieldIndex, showVehicleSelector)) : null
                    ]);
                }
                function repeatField4(field, fieldIndex) {
                    return [this[field] && this[field] !== 'Universal' ? _createElement('div', {
                            'className': 'fitment-row',
                            'key': this[field]
                        }, _createElement('div', { 'className': 'fitment-cell' }, window.themeSettings?.locales?.ymm[field.toLowerCase()] || field), scopeShowVehicleSelector3.apply(this, [
                            field,
                            fieldIndex
                        ])) : null];
                }
                return itemsIndex !== 0 ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'fitment-table__inner-container',
                        'key': '7'
                    },
                    _map(this.fields, repeatField4.bind(this))
                ]) : null;
            }, { count: undefined })];
    }
    return this.vehicleSpecific || this.manyFitments ? _createElement('div', {
        'className': 'cm_fitment-table fitment-table-container',
        'key': '0'
    }, this.vehicleSpecific ? _createElement('div', {
        'className': 'fitment-table-title vehicle-specific',
        'key': '108'
    }) : null, this.manyFitments ? _createElement('div', {
        'className': 'fitment-table-title many-fitments',
        'key': '204'
    }, 'Currently displaying 1000 fitments, yet the product has additional fitments.') : null, this.template === 'horizontal' ? _createElement('div', {
        'className': 'cm_fitment-table__horizontal cmTemplate_horizontal',
        'key': '362'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ])) : null, this.template === 'vertical' ? _createElement('div', {
        'className': 'cm_fitment-table__vertical cmTemplate_vertical',
        'key': '8784'
    }, _createElement.apply(this, [
        'div',
        { 'className': 'fitment-table-body cmRepeater_items' },
        _map(this.items, repeatItems2.bind(this))
    ])) : null) : null;
}
        export const componentNames = ["cm:yearsSelect","cm:vehicleSelector","cm:yearsSelect","cm:vehicleSelector"]