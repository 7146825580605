
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function garageRT () {
    function onKeyDown1(e) {
        {
            if (e.target.closest('.cm_toggle-dropdown') && e.type === 'keydown' && e.key === 'Enter') {
                e.target.click();
            }
        }
    }
    return _createElement('div', {
        'className': 'cm_garage' + (this.isDropdownVisible ? ' cropped' : '') + (!this.isVehicleSelected && !this.isEmpty && this.isDropdownVisible ? ' extend' : ''),
        'onKeyDown': onKeyDown1.bind(this)
    }, _createElement('a', {
        'className': 'cm_toggle-dropdown garage-button',
        'title': 'Garage: ' + this.size + ' item(s)',
        'tabIndex': '0',
        'role': 'button'
    }, _createElement('div', { 'className': 'garage-icon-svg' }, !this.isDropdownVisible ? [_createElement('svg', {
            'width': '20',
            'height': '13',
            'viewBox': '0 0 20 13',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'aria-hidden': 'true',
            'key': '5270'
        }, _createElement('path', {
            'd': 'M1.90735e-06 -1.74846e-06L7.70852e-07 13L2 13L2 3.71428L18 3.71429L18 13L20 13L20 0L10 -8.74228e-07L1.90735e-06 -1.74846e-06ZM3 4.74603L17 4.74603L17 6.80952L3 6.80952L3 4.74603ZM3 10.9365L17 10.9365L17 13L3 13L3 10.9365ZM3 7.84127L17 7.84127L17 9.90476L3 9.90476L3 7.84127Z',
            'fill': '#1A1717'
        }))] : null, this.isDropdownVisible ? [_createElement('svg', {
            'width': '20',
            'height': '13',
            'viewBox': '0 0 20 13',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'aria-hidden': 'true',
            'key': '10130'
        }, _createElement('path', {
            'd': 'M7.70852e-07 13L1.90735e-06 -1.74846e-06L10 -8.74228e-07L20 0L20 13L18 13L18 3.71429L2 3.71428L2 13L7.70852e-07 13Z',
            'fill': '#1A1717'
        }))] : null), _createElement('span', { 'className': 'garage-label' }, 'MY GARAGE'), _createElement('div', { 'className': 'garage-chevron' }, [_createElement('svg', {
            'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
            'width': '11',
            'height': '7',
            'viewBox': '0 0 11 7',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'aria-hidden': 'true',
            'key': '14190'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
            'fill': '#1A1717'
        }))])), _createElement('div', { 'className': 'garage-vehicle' }, this.vehicleValues?.map(v => v.value).join(' / ')), this.isVehicleSelected ? _createElement('div', {
        'className': 'discard-vehicle',
        'onClick': () => this.removeSelectedVehicle(),
        'key': '2015'
    }, [_createElement('svg', {
            'width': '10',
            'height': '10',
            'viewBox': '0 0 10 10',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'aria-hidden': 'true',
            'key': '21300'
        }, _createElement('path', {
            'd': 'M0.502101 8.67349L4.0042 5.17139L0.502101 1.66929L1.66947 0.501918L5.17157 4.00402L8.67367 0.501918L9.84104 1.66929L6.33894 5.17139L9.84104 8.67349L8.67367 9.84086L5.17157 6.33875L1.66947 9.84085L0.502101 8.67349Z',
            'fill': '#FD4A3F'
        }))]) : null, [this.garageDropdown(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', {
                            'className': 'garage-item',
                            'role': 'listitem'
                        }, _createElement('span', {
                            'className': 'select-model' + (this.isSelected ? ' selected' : ''),
                            'data-cm-role': 'select-vehicle',
                            'tabIndex': '0'
                        }, '\n          ', this.vehicleValues?.map(v => v.value).join(' / '), '\n          ', _createElement('span', {
                            'className': 'remove-model',
                            'data-cm-role': 'remove-vehicle',
                            'tabIndex': '0',
                            'role': 'button',
                            'aria-label': 'Remove ' + this.vehicleValues?.map(v => v.value).join(' / ')
                        }, [_createElement('svg', {
                                'width': '10',
                                'height': '10',
                                'viewBox': '0 0 10 10',
                                'fill': 'none',
                                'xmlns': 'http://www.w3.org/2000/svg',
                                'aria-hidden': 'true',
                                'key': '4510'
                            }, _createElement('path', {
                                'd': 'M0.502101 8.67349L4.0042 5.17139L0.502101 1.66929L1.66947 0.501918L5.17157 4.00402L8.67367 0.501918L9.84104 1.66929L6.33894 5.17139L9.84104 8.67349L8.67367 9.84086L5.17157 6.33875L1.66947 9.84085L0.502101 8.67349Z',
                                'fill': '#FD4A3F'
                            }))])));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_garageDropdown' }, this.template === 'list' ? _createElement('div', {
                'className': 'cmTemplate_list',
                'key': '31'
            }, _createElement('div', { 'className': 'garage-content' }, _createElement.apply(this, [
                'div',
                {
                    'role': 'list',
                    'className': 'cmRepeater_items'
                },
                _map(this.items, repeatItems1.bind(this))
            ]), _createElement('div', {
                'className': 'clear-garage',
                'data-cm-role': 'clear-garage',
                'tabIndex': '0',
                'role': 'button'
            }, 'Clear all Vehicles'), [this.vehicleWidget(function () {
                    function repeatSelects1(selects, selectsIndex) {
                        return [selects(function () {
                                function repeatEntry1(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry2(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry3(entry, entryIndex) {
                                    return _createElement('option', {
                                        'disabled': this.loading,
                                        'value': entry.term,
                                        'key': entry.value
                                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                }
                                function repeatEntry4(entry, entryIndex) {
                                    return _createElement('div', {
                                        'className': 'option' + (entry.selected ? ' cm_checked' : ''),
                                        'key': entry.value,
                                        'onClick': () => this.onChange(entry.term)
                                    }, _createElement('div', {
                                        'className': 'cm_dropdown_value',
                                        'role': 'option',
                                        'aria-selected': entry.selected
                                    }, entry.value));
                                }
                                return _createElement('div', { 'className': this.extraClassName }, _createElement('div', {
                                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                    'aria-label': this.useNativeDropdown ? '' : `${ this.title } ${ this.selectedEntries.map(e => e.value).join(', ') }`,
                                    'tabIndex': this.useNativeDropdown ? '-1' : '0'
                                }, _createElement('select', {
                                    'className': 'cm_select_inner-select cm_select__pretty' + (this.disabled && !this.firstSelectedTerm ? ' disabled' : '') + (this.isDropdownVisible ? ' cropped' : ''),
                                    'onChange': this.onChange,
                                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                    'disabled': this.disabled,
                                    'aria-label': this.useNativeDropdown ? this.title : '',
                                    'tabIndex': this.useNativeDropdown ? '0' : '-1'
                                }, this.useNativeDropdown ? [
                                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                        'value': '',
                                        'key': 'null-option'
                                    }, '\n          ', '', this.title, '\n        ') : null,
                                    this.loading ? _createElement('option', {
                                        'key': 'loading-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '12281'
                                        }, 'Popular Makes'),
                                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                        _createElement('option', {
                                            'className': 'cm_option_title',
                                            'disabled': true,
                                            'key': '12284'
                                        }, 'All Makes'),
                                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                    ] : null,
                                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                ] : null, !this.useNativeDropdown ? _createElement('option', {
                                    'key': '_current',
                                    'value': '_current'
                                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' }${ this.title }`, '\n      ') : null), [_createElement('svg', {
                                        'className': 'icon-chevron' + (this.isDropdownVisible ? ' rotated' : ''),
                                        'width': '11',
                                        'height': '7',
                                        'viewBox': '0 0 11 7',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'aria-hidden': 'true',
                                        'key': '30240'
                                    }, _createElement('path', {
                                        'fillRule': 'evenodd',
                                        'clipRule': 'evenodd',
                                        'd': 'M1.11494 0.5L4.87165 4.67412C4.97096 4.78446 5.14398 4.78446 5.2433 4.67412L9 0.5L10.1149 1.50345L6.35824 5.67756C5.66306 6.44998 4.45188 6.44998 3.75671 5.67756L0 1.50345L1.11494 0.5Z',
                                        'fill': '#1A1717'
                                    }))]), !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                        'role': 'listbox',
                                        'key': '3516'
                                    },
                                    this.loading ? _createElement('div', {
                                        'className': 'option',
                                        'key': 'null-option',
                                        'disabled': true
                                    }, '...loading...') : null,
                                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                ]) : null);
                            }, { count: undefined })];
                    }
                    function onKeyDown2(e) {
                        {
                            if (e.type === 'keydown' && e.key === 'Enter') {
                                this.selectVehicle();
                            }
                        }
                    }
                    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__garage cm_vehicleWidget' }, this.template === 'active' ? _createElement('div', {
                        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
                        'key': '81'
                    }, _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
                        _map(this.selects, repeatSelects1.bind(this))
                    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
                        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button',
                        'onClick': this.selectVehicle,
                        'onKeyDown': onKeyDown2.bind(this),
                        'disabled': !this.allowToSetVehicle,
                        'role': 'button',
                        'tabIndex': '0'
                    }, '\n              Add vehicle\n            ')))) : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })])) : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:vehicleWidget","cm:garageDropdown"]